import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'
import _ from 'lodash'
import { selectors as authSelectors } from '../auth'
import storeDetailsActions from './actions'

class StoreDetailsMiddleware {
  fetchInitialStoreDetailsMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const path = action.payload.location.pathname
      const isCorrectRoute = matchPath(path, { path: '/retail-analytics', exact: true })
      if (isCorrectRoute) {
        dispatch(storeDetailsActions.fetchStore({
          id: authSelectors.getUserSelectedStoreId(getState())
        }))
      }
    }
  }
  fetchStaffReportingMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const path = action.payload.location.pathname
      const match = matchPath(path, { path: '/retail-analytics/staff/:userId/:timePeriod/:chart', exact: true })
      if (match) {
        dispatch(storeDetailsActions.setView(
          _.get(match, 'params.userId')
        ))
      }
    }
  }
  fetchStoreReportingMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const path = action.payload.location.pathname
      const match = matchPath(path, { path: '/retail-analytics/store/:storeId/:timePeriod/:chart', exact: true })
      if (match) {
        dispatch(storeDetailsActions.fetchStore({
          id: _.get(match, 'params.storeId')
        }))
      }
    }
  }
}

export default new StoreDetailsMiddleware()
